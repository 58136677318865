import React from 'react';
import { Container } from '@cloudscape-design/components';

export default function Podcasts(props) {
    const frameStyles = {
        border: "none",
        width: "100%",
        height: "600px"
    }

    return (
        <Container>
            <iframe title="MS Stream" style={frameStyles} src="https://web.microsoftstream.com/embed/channel/0978be9b-c492-4cd3-99ee-4dca3cca9ce7?sort=date"></iframe>
        </Container>
    );
}