import React, { useEffect } from 'react';
import {
    Container,
    Header,
    Spinner
} from '@cloudscape-design/components';
import DatabaseService from '../../services/databaseService';


export default function QuickLinks(props) {

    const [links, setLinks] = React.useState(<Spinner size='big' />)

    useEffect(() => {
        setLinks(DatabaseService.getQuickLinks())
    })

    return (
        <Container fitheight header={
            <Header
            variant="h2">QuickLinks</Header>
        }>
            <ul>
                {links}
            </ul>

        </Container>
    );
}
