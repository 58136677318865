import React from 'react';
import Box from '@cloudscape-design/components/box';
import Popover from '@cloudscape-design/components/popover';
import { SideNavigation } from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';

const commonNavHeader = {
  logo: { alt: "logo", src: process.env.PUBLIC_URL + '/logo.png'},
};
export const commonNavItems = [
  {
    type: 'link', text: 'My Dashboard', href: '/', info: (<Box color="text-status-info" display="inline">
      <Popover
        header="Introducing 'My Dashboard'"
        size="medium"
        triggerType="text"
        content="Here you can now see an overview of all activities of the Cloud Community & the Cloud Competence Center as well as your achieved points."
        renderWithPortal={true}
        dismissAriaLabel="Close"
      >
        <Box
          color="text-status-info"
          fontSize="body-s"
          fontWeight="bold"
          data-testid="new-feature-announcement-trigger"
        >
          New
        </Box>
      </Popover>
    </Box>)
  },
  {
    type: 'link',
    text: 'My Environment',
    href: '/',
    info: (
      <Box color="text-status-info" display="inline">
        <Popover
          header="Introducing 'My Environment'"
          size="medium"
          triggerType="text"
          content="Here you will soon see an overview of all cloud environments you have access to."
          renderWithPortal={true}
          dismissAriaLabel="Close"
        >
          <Box
            color="text-status-info"
            fontSize="body-s"
            fontWeight="bold"
            data-testid="new-feature-announcement-trigger"
          >
            Coming Soon
          </Box>
        </Popover>
      </Box>
    )
  },
  {
    type: 'link', text: 'Community Rules', href: '/rules', info: (<Box color="text-status-info" display="inline">
      <Popover
        header="Introducing 'Community Rules'"
        size="medium"
        triggerType="text"
        content="Here you can see an overview of the rules of the Cloud Community. What levels are there and how do you win points."
        renderWithPortal={true}
        dismissAriaLabel="Close"
      >
        <Box
          color="text-status-info"
          fontSize="body-s"
          fontWeight="bold"
          data-testid="new-feature-announcement-trigger"
        >
          New
        </Box>
      </Popover>
    </Box>)
  },
  {
    type: 'link', text: 'Community Podcasts', href: '/podcast', info: (<Box color="text-status-info" display="inline">
      <Popover
        header="Introducing 'Podcast'"
        size="medium"
        triggerType="text"
        content="Here you can now find all podcasts produced by the Cloud Competence Center."
        renderWithPortal={true}
        dismissAriaLabel="Close"
      >
        <Box
          color="text-status-info"
          fontSize="body-s"
          fontWeight="bold"
          data-testid="new-feature-announcement-trigger"
        >
          New
        </Box>
      </Popover>
    </Box>)
  },
  {
    type: 'link', text: 'Community Events', href: '/events', info: (<Box color="text-status-info" display="inline">
      <Popover
        header="Introducing 'Community Events'"
        size="medium"
        triggerType="text"
        content="Here you can find the schedule of the Cloud Community and the Cloud Competence Center."
        renderWithPortal={true}
        dismissAriaLabel="Close"
      >
        <Box
          color="text-status-info"
          fontSize="body-s"
          fontWeight="bold"
          data-testid="new-feature-announcement-trigger"
        >
          New
        </Box>
      </Popover>
    </Box>)
  },
  {
    type: 'link', text: 'Community FAQ', href: '/',
    info: (
      <Box color="text-status-info" display="inline">
        <Popover
          header="Introducing 'FAQ'"
          size="medium"
          triggerType="text"
          content="Here you will soon find answers to the most frequently asked questions to the Cloud Competence Center."
          renderWithPortal={true}
          dismissAriaLabel="Close"
        >
          <Box
            color="text-status-info"
            fontSize="body-s"
            fontWeight="bold"
            data-testid="new-feature-announcement-trigger"
          >
            Coming Soon
          </Box>
        </Popover>
      </Box>
    )
  },
  {
    type: 'link', text: 'Feature Requests', href: '/',
    info: (
      <Box color="text-status-info" display="inline">
        <Popover
          header="Introducing 'Feature Requests'"
          size="medium"
          triggerType="text"
          content="Here you can soon submit your ideas to improve the Cloud Foundation."
          renderWithPortal={true}
          dismissAriaLabel="Close"
        >
          <Box
            color="text-status-info"
            fontSize="body-s"
            fontWeight="bold"
            data-testid="new-feature-announcement-trigger"
          >
            Coming Soon
          </Box>
        </Popover>
      </Box>
    )
  },
];

function CommonNavigation({
  activeHref,
  header = commonNavHeader,
  items = commonNavItems
}) {
  const navigate = useNavigate()
  return <SideNavigation items={items} header={header} activeHref={activeHref} onFollow={(event) => {
    event.preventDefault();
    navigate(event.detail.href)
  }} />;
}

export function AppSideNavigation() {
  return (
    <CommonNavigation />
  );
}