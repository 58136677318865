import { Header } from "@cloudscape-design/components";

export function AppHeader(props) {
    return (
      <Header variant="h1"
        actions={
          props.actions
        }
        description={`Hey ${props.userName}, here you can find all infromation about the Cloud Community`}>
        {props.header}
      </Header>
    );
  }