import {
  AppLayout,
  BreadcrumbGroup
} from '@cloudscape-design/components';
import './App.css';
import { Route, Routes } from 'react-router-dom'
import "@cloudscape-design/global-styles/index.css"
import Dashboard from './pages/Dashboard';
import Rules from './pages/Rules';
import { AppSideNavigation } from './common/sideNavigation';
import Podcast from './pages/Podcast';
import Events from './pages/Events';

function Breadcrumbs() {
  const breadcrumbItems = [
    {
      text: 'Cloud Community',
      href: '/'
    }
  ];
  return <BreadcrumbGroup items={breadcrumbItems}
    expandAriaLabel="Show path"
    ariaLabel="Breadcrumbs" />;
}

function App() {
  return (
    <div className="App">
    <AppLayout
      toolsHide={true}
      contentType='dashboard'
      breadcrumbs={<Breadcrumbs></Breadcrumbs>}
      navigation={<AppSideNavigation />}
      navigationOpen={true}
      content={
        <Routes>
          <Route exact path='/' Component={Dashboard} />
          <Route path='/rules' Component={Rules} />
          <Route path='/podcast' Component={Podcast} />
          <Route path='/events' Component={Events} />
        </Routes>
      } />
    </div>
  );
}

export default App;
