import React, { useEffect } from 'react';
import {
  Box,
  Container,
  Header,
  Popover,
  Link,
  Modal,
  Grid
} from '@cloudscape-design/components';
import DatabaseService from '../../services/databaseService';

export default function News(props) {

  const [latestFeed, setlatestFeed] = React.useState({})
  const [modalVisible, setModalVisible] = React.useState(false)
  const [feeds, setFeeds] = React.useState([])

  useEffect(() => {
    setlatestFeed(DatabaseService.getLatestFeed())
    setFeeds(DatabaseService.getFeeds())
  }, [feeds])

  return (
    <Container header={
      <Header info={<Box color="text-status-info" display="inline">
        <Popover
          header="News"
          size="medium"
          triggerType="text"
          content={
            "Hier findest du die neuesten Infos zum Cloud Competence Center. Unter alle News kannst du alle Artikel noch einmal nachlesen."
          }
          renderWithPortal={true}
          dismissAriaLabel="Close"
        >
          <Box
            color="text-status-info"
            fontSize="body-s"
            fontWeight="bold"
            data-testid="new-feature-announcement-trigger"
          >
            Info
          </Box>
        </Popover>
      </Box>}
        variant="h2">News</Header>
    }>
      <Box variant='small'>{latestFeed.created}</Box>
      <Box variant='h3'>CloudFeed #{latestFeed.id} - {latestFeed.title}</Box>
      <div dangerouslySetInnerHTML={{ __html: latestFeed.text }}></div>

      <Link onFollow={() => setModalVisible(true)}>All News</Link>
      <Modal
        onDismiss={() => setModalVisible(false)}
        visible={modalVisible}
        header="Alle News"
      >
        <Grid
          gridDefinition={[
            { colspan: { default: 12 } }
          ]}
        >{feeds.map(feed => <Container key={feed.id} header={<div><Box color="text-status-info">{feed.created}</Box><Header variant='h4'>CloudFeed #{feed.id} - {feed.title}</Header></div>}><div dangerouslySetInnerHTML={{ __html: feed.text }}></div></Container>)}
        </Grid>

      </Modal>
    </Container>
  );
}
