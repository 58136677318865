import { collection, query, getDocs, orderBy, doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebase/index'
import {
    Link
} from '@cloudscape-design/components';

var membersService = []
var pointsService = []
var levelService = []
var categoryService = []
var linkService = []
var feedService = []
var eventService = []

const DatabaseService = {

    init: async function (user) {
        membersService = (await getDocs(query(collection(db, "members")))).docs
        pointsService = (await getDocs(query(collection(db, "points"), orderBy("timestamp", "desc")))).docs
        levelService = (await getDocs(query(collection(db, "levels"), orderBy("points")))).docs
        categoryService = (await getDocs(query(collection(db, "categories")))).docs
        linkService = (await getDoc(doc(db, "management", "links"))).data().quickLinks
        feedService = (await getDocs(query(collection(db, "feeds"), orderBy("id", "desc")))).docs
        eventService = (await getDocs(query(collection(db, "events"), orderBy("start")))).docs
        await this.initUser(user)
    },

    getPointsOfMember: function (memberUPN) {
        const pointDocs = this.getPointDocsOfMember(memberUPN)
        var points = 0;

        pointDocs.map((doc) => {
            points = points + doc.data().points
        });

        return points
    },

    getEvents: function () {
        var returnArray = []
        eventService.filter(event => event.data().end.toDate() >= new Date).forEach(event => {
            returnArray.push({
                title: event.data().title,
                topic: event.data().topic,
                location: event.data().location,
                start: event.data().start.toDate().toLocaleString(),
                end: event.data().end.toDate().toLocaleString(),
                contact: event.data().contact,
                participate: <a href={event.data().link}>{event.data().type}</a>,
            })
        })
        return returnArray
    },

    getPointDocsOfMember: function (memberUPN) {
        return pointsService.filter(doc => doc.data().memberUPN === memberUPN)
    },

    getPointDocsOfMemberTable: function (memberUPN) {
        var returnArray = []
        this.getPointDocsOfMember(memberUPN).forEach(doc => returnArray.push({
            timestamp: doc.data().timestamp.toDate().toLocaleDateString(),
            points: doc.data().points,
            type: doc.data().type
        }))
        return returnArray
    },

    getPointsOfMemberLast365Days: function (memberUPN) {
        const pointDocs = this.getPointDocsOfMemberLast365Days(memberUPN)
        var points = 0;
        const lastYear = new Date()
        lastYear.setFullYear(lastYear.getFullYear() - 1);

        pointDocs.map((doc) => {
            if (doc.data().timestamp.toDate() >= lastYear) {
                points = points + doc.data().points
            }
        });

        return points
    },

    getPointDocsOfMemberLast365Days: function (memberUPN) {
        const pointDocs = this.getPointDocsOfMember(memberUPN)
        var points = [];
        const lastYear = new Date()
        lastYear.setFullYear(lastYear.getFullYear() - 1);

        pointDocs.map((doc) => {
            if (doc.data().timestamp.toDate() >= lastYear) {
                points.push(doc)
            }
        });

        return points
    },

    getPointDocsOfMemberLast365DaysTable: function (memberUPN) {
        var returnArray = []
        this.getPointDocsOfMemberLast365Days(memberUPN).forEach(doc => returnArray.push({
            timestamp: doc.data().timestamp.toDate().toLocaleDateString(),
            points: doc.data().points,
            type: doc.data().type
        }))
        return returnArray
    },

    getLevelByPoints: function (points) {
        var level = ""

        levelService.forEach(doc => {
            if (points >= doc.data().points) {
                level = doc.data()
            }
        })

        return level
    },

    getNextLevelByPoints: function (points) {
        var level = {}

        for (let index = 0; index < levelService.length; index++) {
            if (points >= levelService[index].data().points) {
                if (levelService[index + 1]) {
                    level = levelService[index + 1].data()
                }
            }
        }

        return level
    },

    getLevelOfMember: function (memberUPN) {
        const points = this.getPointsOfMember(memberUPN);

        return this.getLevelByPoints(points)
    },

    getMemberInLevel: function (level) {
        var returnArray = []
        // const querySnapshot = await getDocs(query(collection(db, "members")))

        for (let index = 0; index < membersService.length; index++) {
            const levelOfMember = this.getLevelOfMember(membersService[index].data().upn)
            if (levelOfMember.name === level.name) {
                returnArray.push(membersService[index].data())
            }

        }
        return returnArray
    },

    getNewPointsPerCategory: function (memberUPN) {
        const points = this.getPointDocsOfMemberLast365Days(memberUPN).sort((a, b) => a.data().timestamp - b.data().timestamp);
        var returnArray = []
        var months = []
        var pointsByMonth = []

        for (let index = 0; index < points.length; index++) {
            months.indexOf(new Date(points[index].data().timestamp.toDate().getFullYear(), points[index].data().timestamp.toDate().getMonth())) === -1 ? months.push(new Date(points[index].data().timestamp.toDate().getFullYear(), points[index].data().timestamp.toDate().getMonth())) : console.log("This item already exists");
        }

        months.forEach(month => {
            pointsByMonth.push(points.filter(point => point.data().timestamp.toDate().getFullYear() === month.getFullYear() && point.data().timestamp.toDate().getMonth() === month.getMonth()))
        })

        pointsByMonth.forEach(monthly => {
            var monthObject = { date: new Date(monthly[0].data().timestamp.toDate().getFullYear(), monthly[0].data().timestamp.toDate().getMonth()) }

            categoryService.forEach(category => {
                monthObject[category.data().name] = 0
            })

            monthly.forEach(monthlyPoints => {
                monthObject[monthlyPoints.data().type] += monthlyPoints.data().points
            })

            returnArray.push(monthObject)
        })

        return returnArray
    },

    getCategoriesForChart: function (points) {
        var returnArray = []

        categoryService.forEach(category => {
            returnArray.push({
                title: category.data().name,
                type: "bar",
                data: points.map(point => ({ x: point.date, y: point[category.data().name] }))
            })
        })

        return returnArray
    },

    getQuickLinks: function () {
        var returnArray = []

        linkService.forEach(link => {
            returnArray.push(<li key={linkService.indexOf(link)} >
                <Link external variant="info" href={link.link}>
                    {link.name}
                </Link>
            </li>)
        })
        return returnArray
    },

    getLatestFeed: function () {
        return {
            created: feedService[0].data().created.toDate().toLocaleDateString(),
            id: feedService[0].data().id,
            title: feedService[0].data().title,
            text: feedService[0].data().text
        }
    },

    getFeeds: function () {
        var returnArray = []
        feedService.map(feed => returnArray.push({
            created: feed.data().created.toDate().toLocaleDateString(),
            id: feed.data().id,
            title: feed.data().title,
            text: feed.data().text,
        }))

        return returnArray
    },

    getLevels: function () {
        var returnArray = []

        levelService.forEach(level => {
            returnArray.push({
                name: level.data().name,
                points: level.data().points
            })
        })

        return returnArray
    },

    getCategories: function () {
        var returnArray = []

        categoryService.forEach(level => {
            returnArray.push({
                name: level.data().name,
                points: level.data().points,
                shortDescription: level.data().shortDescription
            })
        })

        return returnArray.sort((a, b) => a.points - b.points)
    },

    initUser: async function (user) {
        await setDoc(doc(db, 'members', user.email), {
            displayName: user.displayName,
            upn: user.email
        }, { merge: true }).then(() => {return {
            displayName: user.displayName,
            upn: user.upn
        }}).catch((e) => console.log(e))
    }
};

export default DatabaseService;