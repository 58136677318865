import { Box, Container, ContentLayout, Grid } from '@cloudscape-design/components';
import React from 'react';

import Progress from './dashboard-components/progress';
import NewPointsPerCategory from './dashboard-components/newPointsPerCategory';
import QuickLinks from './dashboard-components/quickLinks';
import News from './dashboard-components/news';
import { auth } from '../firebase';
import { AppHeader } from '../common/appHeader';

export default function Dashboard() {
  return (
    <ContentLayout header={
      <AppHeader
        userName={auth.currentUser.displayName}
        header={"My Dashboard"}
      >
      </AppHeader>
    }>
      <Grid
        gridDefinition={[
          { colspan: { l: 8, m: 8, default: 12 } },
          { colspan: { l: 4, m: 4, default: 12 } },
        ]}
      >
        <Grid
          gridDefinition={[
            { colspan: { l: 12, m: 12, default: 12 } },
            { colspan: { l: 12, m: 12, default: 12 } },
          ]}
        >
          <Progress />
          <NewPointsPerCategory />
        </Grid>
        <Grid
          gridDefinition={[
            { colspan: { l: 12, m: 12, default: 12 } },
            { colspan: { l: 12, m: 12, default: 12 } },
            { colspan: { l: 12, m: 12, default: 12 } },
          ]}
        >
          <News />
          <QuickLinks />
          <Container><Box variant="code">Version: Endor</Box></Container>
        </Grid>
      </Grid>
    </ContentLayout>
  );
}