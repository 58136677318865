import React, { useEffect } from 'react';
import { BarChart, Container, Header, Box } from '@cloudscape-design/components';
import { auth } from '../../firebase/index';
import DatabaseService from '../../services/databaseService';

const numberTickFormatter = value => {
  if (Math.abs(value) < 1000) {
    return value.toString();
  }
  return (value / 1000).toFixed() + 'k';
};

const commonChartProps = {
  loadingText: 'Loading chart',
  errorText: 'Error loading data.',
  recoveryText: 'Retry',
  empty: (
    <Box textAlign="center" color="inherit">
      <b>No data available</b>
      <Box variant="p" color="inherit">
        There is no data available
      </Box>
    </Box>
  ),
  noMatch: (
    <Box textAlign="center" color="inherit">
      <b>No matching data</b>
      <Box variant="p" color="inherit">
        There is no matching data to display
      </Box>
    </Box>
  ),
  i18nStrings: {
    legendAriaLabel: 'Legend',
    chartAriaRoleDescription: 'line chart',
    xAxisAriaRoleDescription: 'x axis',
    yAxisAriaRoleDescription: 'y axis',
    yTickFormatter: numberTickFormatter,
  },
};

const dateFormatter = date =>
  date
    .toLocaleDateString('en-US', {
      month: 'short',
      year: 'numeric',
      hour12: false,
    })
    .split(' ')
    .join('\n');

export default function NewPointsPerCategory() {

  const [pointsPerCategory, setPointsPerCategory] = React.useState([])
  const [pointsDomain, setPointsDomain] = React.useState([])
  const [category, setCategory] = React.useState([])

  useEffect(() => {
    setPointsPerCategory(DatabaseService.getNewPointsPerCategory(auth.currentUser.email))
    setPointsDomain(pointsPerCategory.map(({ date }) => date))
    setCategory(DatabaseService.getCategoriesForChart(pointsPerCategory))
  }, [pointsPerCategory])

  return (
    <Container
      className="custom-dashboard-container"
      header={
        <Header variant="h2">
          Cloud Points per Month
        </Header>
      }
    >
      <BarChart
        {...commonChartProps}
        height={300}
        yDomain={[0,200]}
        xDomain={pointsDomain}
        xScaleType="categorical"
        stackedBars={true}
        series={category}
        xTitle="Month"
        yTitle="Total Cloud Points"
        i18nStrings={{
          ...commonChartProps.i18nStrings,
          xTickFormatter: dateFormatter,
        }}
        hideFilter = {true}
      />
    </Container>
  );
}