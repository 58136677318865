import React from 'react';
import {
  Box,
  Spinner
} from '@cloudscape-design/components';

function Content(props) {
  return (
    <Box textAlign='center'>
        <br></br>
        <br></br>
        <img alt={"Cloud Competence Center"} src={process.env.PUBLIC_URL + '/logo.png'}></img>       
        <Box variant='h1'>Loading resources ...  <Spinner size="large" /></Box>
    </Box>
  );
}

const Loading = () => (
  <div>
    <Content />
  </div>
);

Loading.propTypes = {};

Loading.defaultProps = {};

export default Loading;
