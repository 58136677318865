import { ContentLayout, Grid } from '@cloudscape-design/components';
import React from 'react';
import Categories from './rules-components/categories';
import Levels from './rules-components/levels';
import { AppHeader } from '../common/appHeader';
import { auth } from '../firebase';

export default function Rules() {
    return (
        <ContentLayout header={
            <AppHeader
                userName={auth.currentUser.displayName}
                header={"Community Rules"}
            >
            </AppHeader>
        }>
            <Grid
                gridDefinition={[
                    { colspan: { l: 3, m: 3, default: 3 } },
                    { colspan: { l: 9, m: 9, default: 9 } },
                ]}
            >
                <Levels />
                <Categories />
            </Grid>
        </ContentLayout>
    );
}