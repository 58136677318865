import { ContentLayout, Grid } from '@cloudscape-design/components';
import React from 'react';
import EventTable from './events-components/table';
import { AppHeader } from '../common/appHeader';
import { auth } from '../firebase';


export default function Events() {
  return (
    <ContentLayout header={
      <AppHeader
        userName={auth.currentUser.displayName}
        header={"Community Events"}
      >
      </AppHeader>
    }>
      <Grid
        gridDefinition={[
          { colspan: { l: 12, m: 12, default: 12 } },
        ]}
      >
        <EventTable />
      </Grid>
    </ContentLayout>
  );
}