import React, { useEffect } from 'react';
import { Box, Container, Header, ColumnLayout, Link, Modal, Table, Popover, Spinner } from '@cloudscape-design/components';
import ProgressBar from "@cloudscape-design/components/progress-bar";
import { auth } from '../../firebase/index';
import DatabaseService from '../../services/databaseService';

export default function Progress(props) {
  const [modalVisible, setModalVisible] = React.useState(false)
  const [modalYearVisible, setYearModalVisible] = React.useState(false)
  const [modalLevelVisible, setLevelModalVisible] = React.useState(false)

  //Content Variables
  const [pointsOfMember, setPointsOfMember] = React.useState(<Spinner size='large' />)
  const [pointsOfMemberLast365Days, setPointsOfMemberLast365Days] = React.useState(<Spinner size='large' />)
  const [level, setLevel] = React.useState(<Spinner size='big' />)
  const [showProgress, setShowProgress] = React.useState(true)
  const [memberInSameLevel, setMemberInSameLevel] = React.useState([])
  const [nextLevel, setNextLevel] = React.useState({})
  const [pointDocs, setPointDocs] = React.useState([])
  const [pointDocsLast365Days, setPointDocsLast365Days] = React.useState([])

  useEffect(() => {
    //Set Content Variables
    setPointsOfMember(DatabaseService.getPointsOfMember(auth.currentUser.email))
    setPointsOfMemberLast365Days(DatabaseService.getPointsOfMemberLast365Days(auth.currentUser.email))
    setNextLevel(DatabaseService.getNextLevelByPoints(pointsOfMemberLast365Days))
    setLevel(DatabaseService.getLevelOfMember(auth.currentUser.email))
    setMemberInSameLevel(DatabaseService.getMemberInLevel(level))
    setPointDocs(DatabaseService.getPointDocsOfMemberTable(auth.currentUser.email))
    setPointDocsLast365Days(DatabaseService.getPointDocsOfMemberLast365DaysTable(auth.currentUser.email))
    if (level.name === "Expert") {
      setShowProgress(false)
    } else {
      setShowProgress(true)
    }
    
  },[pointsOfMember])

  return (
    <Container
      header={
        <Header variant="h2" info={<Box color="text-status-info" display="inline">
          <Popover
            header="Overview"
            size="medium"
            triggerType="text"
            content={"Here you can find all information about your collected points. Just click on a number and a more detailed view will open."}
            renderWithPortal={true}
            dismissAriaLabel="Close"
          >
            <Box
              color="text-status-info"
              fontSize="body-s"
              fontWeight="bold"
              data-testid="new-feature-announcement-trigger"
            >
              Info
            </Box>
          </Popover>
        </Box>}>
          Level: {level.name} 
        </Header>
      }
    >
      <ColumnLayout columns="0" variant="text-grid">
        <div>
          <Box variant="awsui-key-label">All CloudPoints</Box>
          <Link onFollow={() => setModalVisible(true)} variant="awsui-value-large">
            {pointsOfMember}
          </Link>
          <Modal
            onDismiss={() => setModalVisible(false)}
            visible={modalVisible}
            header="Cloud Points insgesamt"
          >
            <Table
              columnDefinitions={[
                {
                  id: "date",
                  header: "Date",
                  cell: e => e.timestamp,
                  sortingField: "date"
                },
                { id: "category", header: "Category", cell: e => e.type },
                {
                  id: "points",
                  header: "Points",
                  cell: e => e.points
                }
              ]}
              items={pointDocs}
              loadingText="Loading resources"
              sortingDisabled
              visibleColumns={[
                "date",
                "category",
                "points"
              ]}
              empty={
                <Box textAlign="center" color="inherit">
                  <b>No resources</b>
                  <Box
                    padding={{ bottom: "s" }}
                    variant="p"
                    color="inherit"
                  >
                    No resources to display.
                  </Box>
                </Box>
              }
            />
          </Modal>
        </div>
        <div>
          <Box variant="awsui-key-label">Current CloudPoints</Box>
          <Link variant="awsui-value-large" onFollow={() => setYearModalVisible(true)}>
            {pointsOfMemberLast365Days}
          </Link>
          <Modal
            onDismiss={() => setYearModalVisible(false)}
            visible={modalYearVisible}
            header="CloudPoints for the last 365 days"
          >
            <Table
              columnDefinitions={[
                {
                  id: "date",
                  header: "Date",
                  cell: e => e.timestamp,
                  sortingField: "date"
                },
                { id: "category", header: "Category", cell: e => e.type },
                {
                  id: "points",
                  header: "Points",
                  cell: e => e.points
                }
              ]}
              items={pointDocsLast365Days}
              loadingText="Loading resources"
              sortingDisabled
              visibleColumns={[
                "date",
                "category",
                "points"
              ]}
              empty={
                <Box textAlign="center" color="inherit">
                  <b>No resources</b>
                  <Box
                    padding={{ bottom: "s" }}
                    variant="p"
                    color="inherit"
                  >
                    No resources to display.
                  </Box>
                </Box>
              }
            />
          </Modal>
        </div>
        <div>
          <Box variant="awsui-key-label">Member in same Level</Box>
          <Link variant="awsui-value-large" onFollow={() => setLevelModalVisible(true)}>
            {memberInSameLevel.length}
          </Link>
          <Modal
            onDismiss={() => setLevelModalVisible(false)}
            visible={modalLevelVisible}
            header="Member in same Level"
          >
            <Table
              columnDefinitions={[
                {
                  id: "name",
                  header: "Name",
                  cell: e => e.displayName,
                  sortingField: "name"
                },
                { id: "upn", header: "Mail", cell: e => e.upn }
              ]}
              items={memberInSameLevel}
              loadingText="Loading resources"
              sortingDisabled
              visibleColumns={[
                "name",
                "upn"
              ]}
              empty={
                <Box textAlign="center" color="inherit">
                  <b>No resources</b>
                  <Box
                    padding={{ bottom: "s" }}
                    variant="p"
                    color="inherit"
                  >
                    No resources to display.
                  </Box>
                </Box>
              }
            />
          </Modal>
        </div>
      </ColumnLayout>
      {showProgress && <ProgressBar
        value={(pointsOfMemberLast365Days / nextLevel.points * 100).toFixed(0)}
        additionalInfo={(nextLevel.points - pointsOfMemberLast365Days) + " Cloud Points left for " + nextLevel.name + " level"}
      />}
    </Container>
  );
}