import React, { useEffect } from 'react';
import { Box, Table, Button, Header, Popover } from '@cloudscape-design/components';
import DatabaseService from '../../services/databaseService';

export default function EventTable(props) {

    //Content Variables
    const [events, setEvents] = React.useState()

    useEffect(() => {
        //Set Content Variables
        if(!events){
            setEvents(DatabaseService.getEvents())
        }
      
    })

    return (
        <Table
        header={
            <Header variant="h2" info={<Box color="text-status-info" display="inline">
                <Popover
                    header="Overview"
                    size="medium"
                    triggerType="text"
                    content={"Here you can find all the information about Cloud Community Events."}
                    renderWithPortal={true}
                    dismissAriaLabel="Close"
                >
                    <Box
                        color="text-status-info"
                        fontSize="body-s"
                        fontWeight="bold"
                        data-testid="new-feature-announcement-trigger"
                    >
                        Info
                    </Box>
                </Popover>
            </Box>}>
                Overview
            </Header>
        }
        items = {events}
      columnDefinitions={[
        {
          id: "title",
          header: "Title",
          cell: item => item.title || "-"
        },
        {
          id: "topic",
          header: "Topic",
          cell: item => item.topic || "-"
        },
        {
          id: "location",
          header: "Location",
          cell: item => item.location || "-"
        },
        {
          id: "start",
          header: "Start",
          cell: item => item.start || "-"
        },
        {
          id: "end",
          header: "End",
          cell: item => item.end || "-"
        },
        {
          id: "contact",
          header: "Contact",
          cell: item => item.contact || "-"
        },
        {
          id: "participate",
          header: "Participate",
          cell: item => item.participate || "-"
        }
      ]}
      loadingText="Loading resources"
      sortingDisabled
      stickyHeader
      stripedRows
      wrapLines
      empty={
        <Box textAlign="center" color="inherit">
          <b>No resources</b>
          <Box
            padding={{ bottom: "s" }}
            variant="p"
            color="inherit"
          >
            No resources to display.
          </Box>
          <Button>Create resource</Button>
        </Box>
      }
    />
    );
}