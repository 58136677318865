import { ContentLayout, Grid } from '@cloudscape-design/components';
import React from 'react';
import Podcasts from './podcast-components/podcasts';
import { AppHeader } from '../common/appHeader';
import { auth } from '../firebase';

export default function Podcast() {
  return (
    <ContentLayout header={
      <AppHeader
        userName={auth.currentUser.displayName}
        header={"Community Podcasts"}
      >
      </AppHeader>
    }>
      <Grid
        gridDefinition={[
          { colspan: { l: 12, m: 12, default: 12 } },
        ]}
      >
        <Podcasts />
      </Grid>
    </ContentLayout>
  );
}