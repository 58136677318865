import React, { useEffect } from 'react';
import { Box, Header, Table, Popover } from '@cloudscape-design/components';
import DatabaseService from '../../services/databaseService';

export default function Categories(props) {

    //Content Variables
    const [categories, setCategories] = React.useState()
    
    useEffect(() => {
        //Set Content Variables
        if(!categories){
            setCategories(DatabaseService.getCategories()) 
        }      
    })

    return (
        <Table
            header={
                <Header variant="h2" info={<Box color="text-status-info" display="inline">
                    <Popover
                        header="Overview"
                        size="medium"
                        triggerType="text"
                        content={"Here you can find all information about the existing categories in the community."}
                        renderWithPortal={true}
                        dismissAriaLabel="Close"
                    >
                        <Box
                            color="text-status-info"
                            fontSize="body-s"
                            fontWeight="bold"
                            data-testid="new-feature-announcement-trigger"
                        >
                            Info
                        </Box>
                    </Popover>
                </Box>}>
                    Community Categories
                </Header>
            }
            columnDefinitions={[
                {
                    id: "category",
                    header: "Category name",
                    cell: item => item.name || "-"
                },
                {
                    id: "short",
                    header: "Short Description",
                    cell: item => item.shortDescription || "-"
                },
                {
                    id: "points",
                    header: "Points",
                    cell: item => item.points || "-",
                    sortingField: "points"
                }
            ]}
            items={categories}
            loadingText="Loading resources"
            sortingDisabled
            stripedRows
            empty={
                <Box textAlign="center" color="inherit">
                    <b>No resources</b>
                    <Box
                        padding={{ bottom: "s" }}
                        variant="p"
                        color="inherit"
                    >
                        No resources to display.
                    </Box>
                </Box>
            }
        />
    );
}