import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom'
import { auth } from './firebase';
import DatabaseService from './services/databaseService';
import { OAuthProvider, signInWithPopup } from 'firebase/auth';
import Loading from './pages/Loading';

const root = ReactDOM.createRoot(document.getElementById('root'));


auth.onAuthStateChanged(function (user) {
  if (user) {
    root.render(
      <Loading></Loading>
    );
    DatabaseService.init(user).then(() => {
      root.render(
        <React.StrictMode>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </React.StrictMode>
      );
    });
  } else {
    root.render(
      <Loading></Loading>
    );
    const provider = new OAuthProvider("microsoft.com")
    provider.setCustomParameters({
      tenant: "17e9d4a2-1c8d-40d2-af3f-929da470e0c7"
    })
    signInWithPopup(auth, provider).then((result) => {
      DatabaseService.init(result.user).then(() => {
        root.render(
          <React.StrictMode>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </React.StrictMode>
        );
      });
    }).catch(error => console.log(error));
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
