import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const app = initializeApp({
    apiKey: "AIzaSyBC60fORJ_lpcLdDF4YCeUHVLyMlAV0dH0",
    authDomain: "cf-c3-community-aw.firebaseapp.com",
    projectId: "cf-c3-community-aw",
    storageBucket: "cf-c3-community-aw.appspot.com",
    messagingSenderId: "1053986685981",
    appId: "1:1053986685981:web:3b9b8438784bd4d04c32f4"
});

let db = getFirestore(app)
let auth = getAuth(app)
auth.tenantId = 'users-90740'
  

export {
    app,
    db,
    auth
}