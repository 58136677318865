import React, { useEffect } from 'react';
import { Box, Header, Table, Popover } from '@cloudscape-design/components';
import DatabaseService from '../../services/databaseService';

export default function Levels(props) {

    //Content Variables
    const [levels, setLevels] = React.useState()

    useEffect(() => {
        //Set Content Variables
        if(!levels){
            setLevels(DatabaseService.getLevels())
        }
        

    })

    return (
        <Table
            header={
                <Header variant="h2" info={<Box color="text-status-info" display="inline">
                    <Popover
                        header="Overview"
                        size="medium"
                        triggerType="text"
                        content={"Here you can find all the information about the existing levels in the community."}
                        renderWithPortal={true}
                        dismissAriaLabel="Close"
                    >
                        <Box
                            color="text-status-info"
                            fontSize="body-s"
                            fontWeight="bold"
                            data-testid="new-feature-announcement-trigger"
                        >
                            Info
                        </Box>
                    </Popover>
                </Box>}>
                    Community Level
                </Header>
            }
            columnDefinitions={[
                {
                    id: "level",
                    header: "Level name",
                    cell: item => item.name || "-"
                },
                {
                    id: "points",
                    header: "Points",
                    cell: item => item.points || "-",
                    sortingField: "points"
                }
            ]}
            items={levels}
            loadingText="Loading resources"
            sortingDisabled
            stripedRows
            empty={
                <Box textAlign="center" color="inherit">
                    <b>No resources</b>
                    <Box
                        padding={{ bottom: "s" }}
                        variant="p"
                        color="inherit"
                    >
                        No resources to display.
                    </Box>
                </Box>
            }
        />
    );
}